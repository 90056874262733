@import "colours";

.mfa-base-wrapper {
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
}
.mfa-base {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $mc-white-01;

  &:hover {
    cursor: help;
  }
}

.mfa-base.enabled {
  background-color: #2A8A41;
}

.mfa-base.disabled {
  background-color: $mc-blue-01;
}
