@import "colours";
@import "typography";
@import "variables";

.sign-in-page-wrapper {
  background-image: url("https://s3.eu-west-1.amazonaws.com/grs.static.medicare-ems.co.uk/img-medicare-bg-2560x1440-v3.jpg");
  background-repeat: no-repeat!important;
  background-size: cover!important;
  height: 100%;
  left: 0;
  margin: 0!important;
  padding: 0!important;
  position: absolute;
  right: 0;
  top: 0;
  background-position: 51% 0;
  overflow: hidden;
}

.sign-in-modal-wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 1;
  pointer-events: visible;
  position: fixed;
  right: 0;
  top: 0;
  transition: all .3s ease-in-out;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
}

.sign-in-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  background-color: white;
  border-radius: 15px 15px 10px 10px;
  margin: 10px;

  box-shadow: rgba($mc-blue-01, 0.5) 0 0 5px 7px;
}

.sign-in-mc-logo {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: $mc-blue-01;
  padding: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sign-in-form-item {
  margin: 10px 0 20px 0;
}

.forgot-password-form-item {
  margin: 20px 0;
}

.forgot-password-form-item,
.sign-in-form-item {
  width: 100%;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin: 40px 0 0 0;
  }
}

.sign-in-form-label {
  font-family: $header-font;
  margin-bottom: 0;
  margin-left: 5px;
}

.sign-in-form-input {
  font-family: $body-font;
  padding: 7px 5px;
  background-color: $mc-white-01;
  border: transparent;
  border-radius: 5px;
}

.sign-in-button {
  border-radius: 99px;
  padding: 10px 20px;
  font-family: $body-font;
  font-weight: 600;

  background-color: $mc-yellow-01;
  border: 1px solid $mc-yellow-01;
  color: $mc-blue-01;

  transition: all ease $animation-duration;

  &:hover:not(:disabled) {
    background-color: $mc-blue-01;
    border: 1px solid $mc-blue-01;
    color: $mc-yellow-01;
    cursor: pointer;

    transform: scale(1.02);
  }

  &:disabled {
    background-color: $mc-grey-01;
    border: 1px solid $mc-grey-01;
    color: $mc-blue-01;
    cursor: not-allowed;
  }
}

.sign-in-failed {
  color: $mc-red-01;
  border: 1px solid $mc-red-01;
  background-color: $mc-white-01;
  padding: 7px;
}
.sign-in-loading {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.forgot-password {
  color: $mc-blue-01;
  padding-left: 5px;

  transition: color ease $animation-duration;

  &:hover {
    cursor: pointer;

    color: $mc-dark-blue-hover;
    text-decoration: underline;
  }
}

.forgot-password-info {
  padding-left: 5px;
}

.valid-rule,
.invalid-rule,
.forgot-password-info,
.sign-in-form-input,
.sign-in-failed,
.forgot-password {
  font-size: 14px;
}

.valid-rule {
  color: $mc-green-01;
}

.invalid-rule {
  color: $mc-red-01;
}

