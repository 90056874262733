@import "colours";
@import "variables";
@import "typography";

.input-fields {
  background-color: $mc-white-01;
  width: 100%;
  border: 1px solid #ebeef2;
  font-family: $body-font;
  min-width: 195px !important;
  padding: 9px 15px;
  //margin-top: 0.25rem;

  &.error {
    border: 1px solid $mc-red-01;
  }
}

.text-area-inputs {
  min-height: 200px;
}

.input-wrapper {
  position: relative;
}

.input-field-wrapper {
  display: flex;
  flex-direction: row;
}


.field-label {
  color: $mc-blue-01;
  font-size: 0.938rem;
  font-weight: 400;
}

.flag-label {
  color: $mc-blue-01;
  font-size: 0.825rem;
  margin-right: 1rem;
}

.field-error-message,
.field-label.error {
  color: $mc-red-01;
}

.field-error-message {
  margin: 0;
}

.field-error-message {
  font-family: $body-font;
}
.field-error-message-placeholder {
  width: 100%;
  height: 18px;
}
