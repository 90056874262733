@import "colours";
@import "typography";
@import "variables";

.toggle-wrapper-lg {
  padding: 0 15px !important;
}

.toggle-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-control {
  position: relative;
  padding: 3px 0;
  display: flex;
  align-items: center;
}

input[type='checkbox'].dmcheck {
  width: 65px;
  height: 32px;
  border-radius: 15px;
  background-color: $mc-grey-05;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;

  &:checked + label {
    left: 36px;
    background: white;
  }
  &:checked {
    background-color: $mc-blue-01;
  }

  &:focus-visible {
    outline: solid 2px white;
  }

  & + label {
    border: 1px solid white;
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: 6px;
    left: 4px;
    background: white;
  }
}

input[type='checkbox'].dmcheck:disabled {
  &:checked + label {
    background: $mc-yellow-disabled;
  }

  & + label {
    background: $mc-dark-blue-disabled
  }
}
