@import "~bootstrap";
@import "~react-toastify/dist/ReactToastify.css";
@import "~pulse-modal/dist/styles/scss/index";
@import "~store-fetch-wrappers/dist/styles/scss/index";
@import "~pulse_table/dist/styles/scss/index";

@import "core/animations";
@import "core/buttons";
@import "core/cards";
@import "core/colours";
@import "core/dropdown";
@import "core/filters";
@import "core/form";
@import "core/icons";
@import "core/inputs";
@import "core/mfa";
@import "core/modal";
@import "core/navigation";
@import "core/number-bubble";
@import "core/page";
@import "core/pagination";
@import "core/react-date-picker";
@import "core/sidebar";
@import "core/sigin";
@import "core/staffform";
@import "core/tab";
@import "core/table";
@import "core/toast";
@import "core/toggle";
@import "core/tooltip";
@import "core/typography";
@import "core/utility";
@import "core/variables";

@import "widgets/tabs";
@import "widgets/topbar";

@import "pages/stafflist";
@import "pages/dashboard";

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
