.staff-list-table {
  margin-left: 1rem;
  margin-right: 1rem;

  .pulse-table-header-item {
    width: auto;

    &:nth-child(5) {
      width: 1%;
    }
    &:nth-child(6) {
      width: 1%;
    }
    &:nth-child(7) {
      width: 1%;
    }
  }

  .pulse-table-row-item {
    width: inherit;
  }
}