@import "colours";

.mc-card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  margin: 2rem 2rem;
}

.mc-card-wrapper {
  padding: 2rem;
}

.card-spacer {
  height: 1px;
  width: 100%;
  background: $mc-white-01;
}
