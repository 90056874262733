@import "colours";
@import "typography";

.staff-form-body-wrapper {
  display: flex;
  flex-direction: row;

}

.staff-form-body.left {
  width: 20%;
}

.staff-form-body.right {
  width: 80%;
}

.staff-form-section-label {
  font-weight: 500;
}

.staff-access-detail-header {
  background: #EBEEF2;
  font-size: 0.9rem;
  color: rgba($mc-blue-01, 0.6);

  th {
    font-weight: 500;
    padding: 1rem 0 0 1rem;
    font-family: $body-font;
  }
}

.staff-access-detail-row {
  background-color: #EBEEF2;

  td {
    padding: 0.5rem 1rem 0.5rem 1rem;

    &:first-child {
      //padding: 0.5rem 1rem 1rem 1rem;
    }
  }
}

.access-control {
  margin-top: 1rem;
  padding: 0.25rem;
  background-color: #EBEEF2;;
}


@media screen and (max-width: 768px) {
  .staff-form-body-wrapper {
    flex-direction: column;
  }
  .staff-form-body.left,
  .staff-form-body.right {
    width: 100%;
  }
}
