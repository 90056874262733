@import "colours";
/*@font-face {
  font-family:'Magistral';
  src: url("../fonts/Magistral-Bold.ttf");

}
@font-face {
  font-family:'Montserrat';
  src: url('../fonts/Montserrat-Regular.ttf');
}*/

@font-face {
  font-family:'Inter';
  src: url('../fonts/Inter.ttf');
}

$header-font: Inter;
$body-font: Inter;

li, a {
  text-decoration: none !important;
}

.body-font,
p, a, label {
  font-family: $body-font;
}

h1, h2, h3, h4, h5, h6,
.header-font {
  font-family: $header-font;
}

.pulse-modal-header-item h4 {
  font-size: 1.3rem;
}

h5.mb-0  {
  font-size: 1rem;
}

h5.text-center {
  font-size: 1rem;
}

h6.mb-0 {
  margin-bottom: 0.5rem !important;
  //margin-left: 0.2rem;
}

.point-eight-em {
  font-size: 0.8em !important;
}
.one-em {
  font-size: 1em !important;
}
.one-point-zero-five-em {
  font-size: 1.05em !important;
}

.success-text {
  color: $mc-yellow-01;
}

.error-text {
  color: $mc-red-01;
}

.allowed-text {
  color: $mc-blue-01;
  font-size: 0.8em !important;
}
.disallowed-text {
  color: $mc-red-01;
  font-size: 0.8em !important;
}
