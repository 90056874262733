
.page {
  min-height: 100vh;
  margin: 0 !important;
}

.main-content-overlay {
  z-index: 1;
  background-color: #fff !important;
}
.z-index-fix {
  //position: relative;
  z-index: 1;
}

.main-layout {
  position: relative;
  z-index: 2;
}

.center-items {
  display: grid;
  justify-content: center;
  align-items: center;
}

.background-stars {
  background-image: url(https://medicare-ems.co.uk/wp-content/uploads/2022/03/Asset-4.svg);
}

.bg-arrows {
  background-image: url(https://medicare-ems.co.uk/wp-content/uploads/2022/03/Arrows-Grey.svg);
}

.background-stars,
.bg-arrows {
  background-size: cover!important;
}

.align-right {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.mr-3 {
  margin-right: 0.5rem !important;
}