@import "colours";

.mc-form-heading {
  background-color: #ebeef2;
  color:#7b8185;
  padding: 1rem 1rem 1rem 1rem;
  //margin: 0 !important;
  margin-top: 1rem;
}

.mc-form-row-optional,
.mc-form-row {
  padding: 15px 25px 15px 31px !important;
  background-color: #ebeef2;
}

.row-modifier .row {
  margin-bottom: 1.5rem;
  padding-right: 1rem;
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
  background-color: #fff;
}
}

.readonly-warning {
  background-color: $mc-yellow-01;
  color: $mc-blue-01;
}

.form-row-wrapper {
  margin-bottom: 0.5rem;
}
